<template>
	<div class="notice_info">
		<div class="notice-wrap">
			<el-breadcrumb separator="/" class="path">
				<el-breadcrumb-item :to="{ path: '/' }" class="path-home">
					<i class="n el-icon-s-home"></i>首页
				</el-breadcrumb-item>
				<el-breadcrumb-item class="path-help">站内信</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="notice" v-loading="loading">
				<!-- <div class="menu">
					<div class="title">最新消息</div>
					<div class="item" v-for="item in masterLists" :key="item.id" @click="detil(item.id)">
						<div class="item-name" @click="menuOther(item.id)">{{ item.title }}</div>
					</div>
				</div> -->
				<div class="list-wrap">
					<div class="notice-title">
						订单信息
						<div class="master-seed">
						</div>
					</div>
					<div class="empty" v-if="masterLists.length ==  0">
						<div class="empty_img">
							<img :src="$util.img('upload/uniapp/sitemessage/empty.png')" alt="" />
						</div>
						<div class="color-tip margin-top margin-bottom">暂无信息</div>
					</div>
					<div v-else>
						<div class="site-msg-order" >
								<div slot="list">
									<div class="flex-row-center" v-for="(item, index) in OrderList" :key="index" >
										<div class="left-slide-operation-item-div">
											<div class="time"><span>{{item.time}}</span></div>
												<div class="flex-row-center" @click.stop="getSeed(item.id, item.site_id)">
													<div class="order-item" @click.stop="toOrderDetail(item.json, item.message_json.order_type)">
														<div class="order-title">{{item.title}}
														<span class="delete" @click.stop="getDeleteMemberSubMessage(item.id, item.site_id)">删除</span>
														</div>
														<div class="order-detail">
															<div class="order-img">
																<img :src="$util.img(item.image)" mode="aspectFill" />
															</div>
															<div class="order-content">
																<div class="order-span color-tip font-size-tag">{{item.content}}</div>
															</div>
															<div class="div-order color-base-border color-base-span" type="default">查看订单</div>
														</div>
													</div>
												</div>
										</div>
									</div>
								</div>
								<div class="pager">
									<el-pagination
										background
										:pager-count="5" 
										:total="total" 
										prev-span="上一页" 
										next-span="下一页" 
										:current-page.sync="queryinfo.page" 
										:page-size.sync="queryinfo.page_size"
										@size-change="handlePageSizeChange" 
										@current-change="handleCurrentPageChange" 
										>
									</el-pagination>
								  </div>
						</div>
					</div>
				</div>
			</div>
			<!-- <div class="pager">
				<el-pagination background :pager-count="5" :total="total" prev-span="上一页" next-span="下一页"
					:current-page.sync="queryinfo.page" :page-size.sync="queryinfo.page_size"
					@size-change="handlePageSizeChange" @current-change="handleCurrentPageChange" hide-on-single-page>
				</el-pagination>
			</div> -->
		</div>
	</div>
</template>

<script>
	import {
		getSiteMemberSubMessageOrderList,
		seed,
		editallseed,
		deletemembersubmessage
	} from "@/api/cms/master";
	export default {
		data() {
			return {
				OrderList: [],
				seed: {},
				deletemembersubmessage: {},
				id: '',
				site_id: '',
				sub_type: '',
				touchId: '',
				flag: true,
				isAction: false, // 是否操作删除
				startX: '', // 触摸开始位置
				moveClass: '',
				endX: '', // 触摸结束位置
				time: '',
				loading:true,
				total: 0,
				OrderList: [],
				seed: {},
				deletemembersubmessage: {},
				id: '',
				site_id: '',
				sub_type: '',
				touchId: '',
				flag: true,
				isAction: false, // 是否操作删除
				startX: '', // 触摸开始位置
				moveClass: '',
				endX: '', // 触摸结束位置
				time: '',
				queryinfo: {
				  page: 1,
				  page_size: 10,
				},
				masterLists:[]
			}
		},
		onShow() {
			this.getEditallSeed()
		},
		mounted() {
			let param = this.$router.app._route.query
			this.sub_type = param.sub_type
			this.id = param.id
			this.site_id = param.site_id
			this.getSiteMemberSubMessageOrderList()
		},
		onUnload() {
			this.getEditallSeed()
		},
		methods: {
			handlePageSizeChange(newsize) {
			  this.queryinfo.page_size = newsize;
			  this.getSiteMemberSubMessageOrderList()
			},
			handleCurrentPageChange(newnum) {
			  this.queryinfo.page = newnum;
			  this.getSiteMemberSubMessageOrderList()
			},
			operationCallBack(item) {
				let that = this;
				uni.showModal({
					title: '删除',
					content: '请问要删除本条消息吗？',
					success: res => {
						if (res.confirm) {
							that.getDeleteMemberSubMessage(item.id, item.site_id)
						}
					}
				});
			},
			getSiteMemberSubMessageOrderList(){
				getSiteMemberSubMessageOrderList(this.queryinfo)
					.then(res => {
						if (res.code == 0 && res.data) {
							for (var i = 0; i < res.data.list.length; i++) {
								res.data.list[i].sended_time = this.$util.timeStampTurnTime(res.data.list[i].sended_time)
								res.data.list[i].time = this.$util.getSitemessageDate(res.data.list[i].sended_time)
								
								res.data.list[i].message_json = JSON.parse(res.data.list[i].message_json)
								res.data.list[i].del = false
							}
							let newArr = res.data.list;
							this.masterLists = res.data.list
							this.timeNow = Date.parse(new Date()) / 1000;
							this.OrderList = newArr; //追加新数据
							this.total = res.data.count;
						}
						this.loading = false;
					})
					.catch(err => {
						this.loading = false;
						this.$message.error(err.message);
					});
			},
			// 获取订单消息
			
			// 消息主表点击已接收 
			getSeed(id, site_id) {
				seed( {
						id: id,
						site_id: site_id
					})
				.then(res => {
					if (res.code == 0 && res.data) {
						this.seed = res.data
					}
					this.loading = false;
				})
				.catch(err => {
					this.loading = false;
					this.$message.error(err.message);
				});
			},
			
			// 全部消息已读
			getEditallSeed() {
				editallseed( {
						sub_type: this.sub_type,
						site_id: this.site_id
					})
				.then(res => {
					if (res.code == 0 && res.data) {
						this.editallSeed = res.data
					}
					this.loading = false;
				})
				.catch(err => {
					this.loading = false;
					this.$message.error(err.message);
				});
			},
			
			// 删除单条信息
			getDeleteMemberSubMessage(id, site_id) {
				deletemembersubmessage( {
					id: id,
					site_id: site_id
					})
				.then(res => {
					if (res.code == 0 && res.data) {
						this.deletemembersubmessage = res.data
						this.getSiteMemberSubMessageOrderList()
						this.$forceUpdate()
					}
					this.loading = false;
				})
				.catch(err => {
					this.loading = false;
					this.$message.error(err.message);
				});
			},
			toOrderDetail(order_id, order_type) {
				let data = JSON.parse(JSON.parse(order_id))
				this.OrderList.is_seed == 1
				// this.$util.redirectTo('/promotionpages/order/detail/detail', {
				// 	order_id
				// });
				switch (order_type) {
					case 2:
					// 自提订单
						this.$router.push({
							path: '/member/order_detail_pickup',
							query: { order_id: data.order_id }
						});
						break;
					case 3:
						// 本地配送订单
						this.$router.push({
							path: '/member/order_detail_local_delivery',
							query: { order_id: data.order_id }
						});
						break;
					case 4:
						// 虚拟订单
						this.$router.push({
							path: '/member/order_detail_virtual',
							query: { order_id: data.order_id }
						});
						break;
					default:
					this.$router.push('/member/payment_detail?plan_id='+data.plan_id)
						break;
				}
			},
		}
	}
</script>

<style lang="scss">
	.site-msg-order {
	}
	
	.empty {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 10px;
		box-sizing: border-box;
		justify-content: center;
		padding-top: 40px;

		.empty_img {
			width: 63%;
			height: 225px;
			display: flex;
			justify-content: center;
			image {
				width: 100%;
				height: 100%;
			}
		}
	}
	
	.left-slide-operation-item-div {
		padding:10px  30px;
		background-color: #f7f7f7;
		margin-top: 20px;
		border-radius: 10px;
		.time {
			width: 100%;
			height: 35px;
			line-height: 35px;
			text-align: center;
			span {
				padding: 4px 10px;
				border-radius: 2px;
				display: inline-block;
				line-height: 1;
				background-color: rgba(0, 0, 0, .2);
				color: #fff;
				font-size: 12px;
			}
		}
		
		.flex-row-center {
			width: 100%;
			height: 130px;
			box-sizing: border-box;
			overflow: hidden;
		}
	}

	.order-item {
		
	}

	.order-title {
		margin-bottom: 10px;
		margin-bottom: 20px;
		line-height: 1;
		display: flex;
		align-items: center;
		font-size: 16px;
		position: relative;
		span {
			color: red;
			font-size: 18px;
			line-height: 14px;
		}
		.delete{
			position: absolute;
			right: 0px;
			font-size: 14px;
			background-color: #fff;
			cursor: pointer;
		}
	}

	.order-detail {
		display: flex;
		position: relative;
	}

	.order-img {
		width: 75px;
		height: 75px;
		margin-right: 10px;
		border-radius: 15px;
		flex-shrink: 0;
	}

	.order-img image {
		width: 75px;
		height: 75px;
		border-radius: 5px;
	}

	.order-content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-left: 30px;
	}

	.order-span {
		span-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		span-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		-webkit-box-orient: vertical;
		white-space: normal;
		word-break: break-all;
	}

	.div-order {
		border: 1px solid #0CB997;
		width: 80px;
		height: 25px;
		line-height: 25px;
		border-radius: 15px;
		box-sizing: border-box;
		position: absolute;
		right: 0;
		bottom: 0px;
		background-color: #fff;
		text-align: center;
		color: #0CB997;
		cursor: pointer;
	}


	.del-none {
		position: absolute;
		top: 10px;
		right: 45%;
		border-radius: 10px;
		color: #FFFFFF;
		background-color: #000000;
		width: 45px;
		height: 25px;
		span-align: center;
		z-index: 99;
	}

	.border-arrow {
		width: 0;
		height: 0;
		font-size: 0;
		line-height: 0;
		position: absolute;
		left: 10px;
		top: 22px;
	}

	.border-arrow em,
	.border-arrow ins {
		width: 0;
		height: 0;
		display: div;
		position: absolute;
		border: 8px solid transparent;
		border-style: solid dashed dashed dashed;
		transform: rotateX(180deg);
	}

	.border-arrow em {
		border-bottom-color: #000000;
	}

	.border-arrow ins {
		border-bottom-color: #000000;
		top: 1px;
	}

	.move-left {
		transform: translateX(-15%);
		-webkit-transform: translateX(-15%);
	}
	.notice_info {
		background-color: #fff;
	}
	
	.notice-wrap {
		width: 1200px;
		margin: 0 auto;
		background: #ffffff;
	
		.path {
			padding: 15px 0;
		}
	}
	
	.notice {
		background-color: #ffffff;
		min-height: 300px;
		position: relative;
		display: flex;
	
		.menu {
			width: 210px;
			min-height: 300px;
			background: #ffffff;
			border: 1px solid #f1f1ff;
			flex-shrink: 0;
	
			.title {
				padding-left: 16px;
				background: #f8f8f8;
				font-size: $ns-font-size-base;
				height: 40px;
				cursor: pointer;
				color: #666666;
				display: flex;
				align-items: center;
			}
	
			.item-name {
				font-size: $ns-font-size-base;
				cursor: pointer;
				line-height: 40px;
				border-top: 1px solid #f1f1f1;
				padding-left: 25px;
				padding-right: 10px;
				white-space: nowrap;
				overflow: hidden;
				span-overflow: ellipsis;
				height: 40px;
				background: #ffffff;
				color: #666666;
	
				&:hover {
					color: $base-color;
				}
			}
	
			.active {
				color: $base-color;
			}
		}
	
		.list-wrap {
			padding: 0 40px;
			border: 1px solid #f1f1f1;
			width: 100%;
			margin-bottom: 10px;
			padding-bottom: 30px;
			.notice-title {
				position: relative;
				padding: 37px 0 20px 0;
				font-size: 18px;
				border-bottom: 1px dotted #e9e9e9;
			}
	
			.list {
				display: flex;
				justify-content: space-between;
				align-items: center;
	
				&:last-of-type {
					border-bottom: initial;
				}
	
				&:nth-child(2) {
					margin-top: 10px;
				}
	
				.item {
					font-size: $ns-font-size-base;
					white-space: nowrap;
					overflow: hidden;
					span-overflow: ellipsis;
					cursor: pointer;
					padding: 3px 0;
	
					&:hover {
						color: $base-color;
					}
				}
	
				.info {
					display: flex;
					font-size: $ns-font-size-base;
	
					.time {
						margin-right: 10px;
					}
				}
			}
		}
	}
	
	.page {
		span-align: center;
	}
	.master-seed{
		position: absolute;
		right: 30px;
		top: 37px;
		font-size: 14px;
		cursor: pointer;
		color: #ff7386;
	}
	.empty{
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		.empty_img{
			margin:  20px 0 0 0px;
		}
		.color-tip{
			margin: 15px 0;
		}
	}
	.master-item-box{
		margin-top: 20px;
		border-bottom: 1px solid #efefef;
		padding-bottom: 15px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;
		.master-item{
			display: flex;
			.item-icon{
				width: 60px;
				height: 60px;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				color: #fff;
				font-size: 30px;
				margin-right: 25px;
			}
			.order {
				background-color: #3acbe1;
			}
			
			.servicer {
				background-color: #4091ff;
			}
			
			.account {
				background-color: #ff882e;
			}
			
			.delivery {
				background-color: #00ae66;
			}
			
			.group {
				background-color: red;
			}
			
			.promotion {
				background-color: #ff405b;
			}
			
			.promotion::before {
				content: "\e6d3";
			}
			
			.group::before {
				content: "\e66a";
			}
			
			.delivery::before {
				content: "\e70f";
			}
			
			.account::before {
				content: "\e619";
			}
			
			.order::before {
				content: "\e61c";
			}
			
			.servicer::before {
				content: "\e686";
			}
			.item-detail{
				.master-detail{
					display: flex;
					justify-content: space-between;
					.time{
						font-size: 12px;
						color: #8f8f8f;
						position: absolute;
						right: 30px;
					}
				}
			}
		}
		.delete{
			margin-right: 15px;
			color: #b097ef;
			cursor: pointer;
			margin-top: 20px;
		}
	}
	.empty{
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		.empty_img{
			margin:  20px 0 0 0px;
		}
		.color-tip{
			margin: 15px 0;
		}
	}
	.pager{
		margin: 15px 0;
	}
</style>
